div {
  text-align: center;
}

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  padding: 10px;
  max-width: 760px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5%;
}

.input {
  width: 100%;
  height: 5%;
  position: fixed;
  z-index: 100;
}

.main {
  width: 100%;
  height: 170%;
}

.top {
  width: 100%;
  height: 5%;
  background-color: antiquewhite;
  display: flex;
  flex-direction: row;
}

.bottom {
  width: 100%;
  height: 90%;
  background-color: aquamarine;
  display: flex;
  flex-direction: row;
}

.time {
  width: 10%;
  height: 100%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plando {
  width: 45%;
  height: 100%;
  background-color: darkseagreen;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 70%;
  height: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  border: 1px solid black;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
}

.modal button {
  margin-top: 8%;
}

.time div {
  height: 4%;
  text-align: center;
}

.on-off {
  visibility: visible;
}

.button {
  display: flex;
}

.del {
  margin: 1%;
  float: right;
}

.modal-xbutton {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
}

.modal-input {
  min-width: 60%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-input input {
  width: 90%;
  height: 20%;
  margin: 5%;
}